import {
	createStore
} from 'vuex'
import {
	getSummary,
	getProvincesummary,
	getCountySummary,
	getCitysummary,
	getProvinceList,
	getCitylist,
	getCountyList
} from '@/api/home'
export default createStore({
	// 数据
	state: {
		// 页面是否加载状态
		isLoding: false,
		// 右上角文字内容
		rightTopText: '区县代理数量',
		// 
		ProvinceName: '',
		// 当前显示地图的编码
		mapCode: 100000,
		mapObjectList: [{
				"name": "中国",
				"id": 100000,
				"top": "130",
				"left": "100"
			},
			{
				"name": "北京市",
				"id": 110000,
				"top": "130",
				"left": "170"
			},
			{
				"name": "天津市",
				"id": 120000,
				"top": "130",
				"left": "170"
			},
			{
				"name": "河北省",
				"id": 130000,
				"top": "140",
				"left": "180"
			},
			{
				"name": "山西省",
				"id": 140000,
				"top": "130",
				"left": "190"
			},
			{
				"name": "内蒙古自治区",
				"id": 150000,
				"top": "180",
				"left": "50"
			},
			{
				"name": "辽宁省",
				"id": 210000,
				"top": "190",
				"left": "70"
			},
			{
				"name": "吉林省",
				"id": 220000,
				"top": "190",
				"left": "70"
			},
			{
				"name": "黑龙江省",
				"id": 230000,
				"top": "170",
				"left": "70"
			},
			{
				"name": "上海市",
				"id": 310000,
				"top": "140",
				"left": "70"
			},
			{
				"name": "江苏省",
				"id": 320000,
				"top": "190",
				"left": "60"
			},
			{
				"name": "浙江省",
				"id": 330000,
				"top": "120",
				"left": "80"
			},
			{
				"name": "安徽省",
				"id": 340000,
				"top": "130",
				"left": "110"
			},
			{
				"name": "福建省",
				"id": 350000,
				"top": "130",
				"left": "100"
			},
			{
				"name": "江西省",
				"id": 360000,
				"top": "130",
				"left": "200"
			},
			{
				"name": "山东省",
				"id": 370000,
				"top": "210",
				"left": "100"
			},
			{
				"name": "河南省",
				"id": 410000,
				"top": "80",
				"left": "80"
			},
			{
				"name": "湖北省",
				"id": 420000,
				"top": "160",
				"left": "80"
			},
			{
				"name": "湖南省",
				"id": 430000,
				"top": "140",
				"left": "80"
			},
			{
				"name": "广东省",
				"id": 440000,
				"top": "140",
				"left": "80"
			},
			{
				"name": "广西壮族自治区",
				"id": 450000,
				"top": "160",
				"left": "80"
			},
			{
				"name": "海南省",
				"id": 460000,
				"top": "140",
				"left": "80"
			},
			{
				"name": "重庆市",
				"id": 500000,
				"top": "140",
				"left": "80"
			},
			{
				"name": "四川省",
				"id": 510000,
				"top": "140",
				"left": "80"
			},
			{
				"name": "贵州省",
				"id": 520000,
				"top": "140",
				"left": "80"
			},
			{
				"name": "云南省",
				"id": 530000,
				"top": "120",
				"left": "80"
			},
			{
				"name": "西藏自治区",
				"id": 540000,
				"top": "210",
				"left": "80"
			},
			{
				"name": "陕西省",
				"id": 610000,
				"top": "100",
				"left": "140"
			},
			{
				"name": "甘肃省",
				"id": 620000,
				"top": "140",
				"left": "80"
			},
			{
				"name": "青海省",
				"id": 630000,
				"top": "180",
				"left": "80"
			},
			{
				"name": "宁夏回族自治区",
				"id": 640000,
				"top": "140",
				"left": "130"
			},
			{
				"name": "新疆维吾尔自治区",
				"id": 650000,
				"top": "140",
				"left": "80"
			},
			{
				"name": "台湾省",
				"id": 710000,
				"top": "140",
				"left": "140"
			},
			{
				"name": "香港特别行政区",
				"id": 810000,
				"top": "140",
				"left": "140"
			},
			{
				"name": "澳门特别行政区",
				"id": 820000,
				"top": "140",
				"left": "140"
			}
		],
		dataMap: new Map([
			['中国', 100000],
			['北京市', 110000],
			['天津市', 120000],
			['河北省', 130000],
			['山西省', 140000],
			['内蒙古自治区', 150000],
			['辽宁省', 210000],
			['吉林省', 220000],
			['黑龙江省', 230000],
			['上海市', 310000],
			['江苏省', 320000],
			['浙江省', 330000],
			['安徽省', 340000],
			['福建省', 350000],
			['江西省', 360000],
			['山东省', 370000],
			['河南省', 410000],
			['湖北省', 420000],
			['湖南省', 430000],
			['广东省', 440000],
			['广西壮族自治区', 450000],
			['海南省', 460000],
			['重庆市', 500000],
			['四川省', 510000],
			['贵州省', 520000],
			['云南省', 530000],
			['西藏自治区', 540000],
			['陕西省', 610000],
			['甘肃省', 620000],
			['青海省', 630000],
			['宁夏回族自治区', 640000],
			['新疆维吾尔自治区', 650000],
			['台湾省', 710000],
			['香港特别行政区', 810000],
			['澳门特别行政区', 820000],
		]),
		// 左侧数据内容
		HomeLeftData: {
			"county": 0,
			"room": 0,
			"user": 52537,
			"teacher": 2874
		},
		// 全国各省数据（当作缓存）
		provinceData: [],
		// 缓存全国数据
		redismapData:[],
		// 地图数据
		mapData: [],
		// 右侧蜂窝数据
		honeycombData: [],
		mapType: 0, // 地图类型 0:区县代理 1:智习室总数,
		mapPorvinceType:1 ,
		max:100,
	},
	// 类似于vue组件中的计算属性，对state数据进行计算（会被缓存）
	getters: {

	},
	// 唯一可以修 改state数据的场所
	mutations: {
		// 设置加载状态
		setLoding(state, payload) {
			state.isLoding = payload
		},
		// 县区代理和智习室切换设置
		setCutMapData(state, payload) {
			state.isLoding = false
			state.mapCode = 100000
			state.rightTopText = payload.mapType ? '智习室总数' : '区县代理总数'
			state.mapType = payload.mapType
			state.mapData = payload.mapData
			state.honeycombData = payload.honeycombData
			state.provinceData = payload.honeycombData
			state.mapPorvinceType = payload.mapPorvinceType
			state.max = payload.max
			state.redismapData = payload.redismapData
		},
		// 赋值初始化数据
		setInitializeData(state, payload) {
			state.isLoding = false
			state.HomeLeftData = {
				...state.HomeLeftData,
				...payload[0].result
			}
			state.mapData = payload[1]
			state.provinceData = payload[2].result
			// console.log('数据更新');
			// console.log(state.mapData);
			state.honeycombData = payload[2].result
			state.redismapData = payload[1]
		},
		// 更新省份数据
		setCitySummary(state, payload) {
			state.isLoding = false
			state.rightTopText = state.mapType ? payload.name + ' - 智习室总数' : payload.name + ' - 区县代理数量'
			state.ProvinceName = payload.name
			state.mapCode = state.mapObjectList.find(e => {
				return e.name == payload.name
			}).id
			state.mapData = payload.mapData
			state.honeycombData = payload.honeycombData
		},
		// 更新市区数据
		setCountyData(state, payload) {
			state.rightTopText = state.mapType ? state.ProvinceName + ' - ' + payload.name + ' - 智习室总数' : state.ProvinceName + ' - ' + payload.name + '- 区县代理数量'
			state.honeycombData = payload.honeycombData
		},
		// 选的是澳门 上海等市 右边返回的数据已经地图数据
		setCountyData1(state, payload) {
			state.honeycombData = payload.honeycombData
			state.mapData = payload.mapData
		},
		// 返回国家地图
		returnChinaMap(state, payload) {
			state.rightTopText = state.mapType ? '智习室总数' : '区县代理总数'
			state.mapCode = 100000
			state.mapData = state.redismapData
			state.honeycombData = state.provinceData
			state.mapPorvinceType = 1
			state.max = state.mapType ? 30 : 100
		},
		// 如果是区县代理的话  市区不显示区县代理人数
		mapPorvinceType(state,payload){
			state.mapPorvinceType = payload.mapPorvinceType
		}
	},
	// 可以包含异步操作
	actions: {
		// 如果是区县代理的话  市区不显示区县代理人数
		setmapPorvinceType(context, payload) {
			context.commit('mapPorvinceType',{
				mapPorvinceType: payload
			})
		},
		// 县区代理和智习室切换
		async cutMap(context, payload) {
			context.commit('setLoding', true)
			let provinceSummaryResponse = undefined;
			// map等于0获取区县代理数据，map等于1获取智习室数据
			if (payload.mapType == 0) {
				provinceSummaryResponse = await getProvinceList();
				// 传入百分比
				const newData= provinceSummaryResponse.result.map(item => {
					return {name:item.name,value:item.percent,percent:item.value};
				});
				context.commit('setCutMapData', {
					honeycombData:provinceSummaryResponse.result,
					mapData: newData,
					mapType: payload.mapType,
					mapPorvinceType:1,
					max:100,
					redismapData:newData
				})
			} else {
				provinceSummaryResponse = await getProvincesummary();
				context.commit('setCutMapData', {
					honeycombData:provinceSummaryResponse.result,
					mapData: provinceSummaryResponse.result,
					mapType: payload.mapType,
					mapPorvinceType:1,
					max:30,
					redismapData:provinceSummaryResponse.result
				})
			}
		},
		// 首次进入页面获取默认数据
		async getInitializeData(context, payload) {
			context.commit('setLoding', true)
			try {
				let summaryResponse = await getSummary();;
				let provinceSummaryResponse = await getProvinceList();;
				const newData= provinceSummaryResponse.result.map(item => {
					return {name:item.name,value:item.percent,percent:item.value};
				});
				const dataArr = [summaryResponse, newData,provinceSummaryResponse];
				context.commit('setInitializeData', dataArr)
			} catch (error) {
				console.log('异常响应');
				console.log(error);
				throw error;
			}
		},
		// 点击省份获取数据
		async getCitySummary(context, payload) {
			context.commit('setLoding', true)
			let res = undefined
			let res1 = undefined
			// 如果点击的省份不是北京市、天津市、重庆市、上海市、澳门特别行政区、香港特别行政区
			if( payload == '澳门特别行政区' || payload == '香港特别行政区'){
				context.commit('setLoding',false)
			}else if(payload != '北京市' && payload != '天津市' && payload != '重庆市' && payload != '上海市'){
				// 判断是智习室还是区县代理
				if (context.state.mapType) {
					// 智习室
					res = await getCitysummary({
						province: payload
					});
				} else {
					// 区县
					res = await getCitylist({
						province: payload
					});
				}
				if(res != undefined){
					// 把百分比变成value传入地图当中
					const newData= res.result.map(item => {
						return {name:item.name,value:item.percent,percent:item.value};
					});
					context.commit('setCitySummary', {
						honeycombData:res.result,
						mapData: newData,
						name: payload,

				})
				}
			}else{
				//点击的省份是上面四个省份的话
				if(context.state.mapType){
					// 地图还是渲染的省份地图
                    res = await getCitysummary({
						province: payload
					});
					// 右边蜂巢的数据更改
					res1 = await getCountySummary({
						province: payload,
						city: ''
					})
				}else{
					res = await getCitylist({
						provincec:payload
					})
					res1 = await getCountyList({
						province:payload,
						city:''
					})
				}
				const newData= res1.result.map(item => {
					return {name:item.name,value:item.percent,percent:item.value};
				});
				if(res != undefined){
					// 把百分比变成value传入地图当中
					context.commit('setCitySummary', {
						mapData: newData,
						name: payload
				})
				}
				if(res1 != undefined){
					context.commit('setCountyData1',{
						honeycombData: res1.result,
						name: payload,
						mapData: newData
					})
				}
			}
		},
		// 点击各市区数据
		async getCountyData(context, payload) {
			let obj = {
				province: context.state.ProvinceName,
				city: payload
			}
			let res = undefined
			if (context.state.mapType) {
				res = await getCountySummary({
					province: context.state.ProvinceName,
					city: payload
				});
			} else {
				res = await getCountyList({
					province: context.state.ProvinceName,
					city: payload
				});

			}
			context.commit('setCountyData', {
				honeycombData: res.result,
				name: payload
			})
		}

	},
	// 模块化管理store（仓库）
	modules: {}
})