<template>
	<div class="left-body">
		<div class="left-body-number">
			<div class="left-body-time">全国数据({{currentDate}}) </div>
			<!-- ,'left-body-number-1':mapType==1  -->
			<div v-bind:class="{ 'left-body-number-1-check': mapType==0}" class="left-body-number-1"
				@click="regionClick">
				<p class="left-body-number-name">区县代理总数</p>
				<b class="left-body-number-value">{{HomeLeftData.county}}</b>
			</div>
			<div v-bind:class="{ 'left-body-number-2-check': mapType==1}" class="left-body-number-2"
				@click="classroomCkick">
				<p class="left-body-number-name">智习室总数</p>
				<b class="left-body-number-value">{{HomeLeftData.room}}</b>
			</div>
			<div class="left-body-number-3">
				<p class="left-body-number-name">用户总数</p>
				<b class="left-body-number-value">{{HomeLeftData.user}}</b>
			</div>
			<div class="left-body-number-4">
				<p class="left-body-number-name">学管师总数</p>
				<b class="left-body-number-value">{{HomeLeftData.teacher}}</b>
			</div>
		</div>
		<div class="left-body-arc">
			<img style="height: 42h;width: 4vw;" src="../../assets/home/left_arc_1.svg" alt="" srcset="" />
			<img style="height: 26vh;margin-left: -2vw;width: 2.1vw;" src="../../assets/home/left_arc_2.svg" alt=""
				srcset="" />
		</div>
	</div>
</template>

<script>
	export default {
		name: 'HomeLeft',
		props: {

		},
		data() {
			return {
				checkRegion: false,
				checkClassroom: false,
				currentDate: '' // 存储当前日期的数据属性
			}
		},
		computed: {
			HomeLeftData() {
				return this.$store.state.HomeLeftData
			},
			mapType() {
				return this.$store.state.mapType
			}
		},
		created() {
			this.getTime()
		},
		methods: {
			// 点击区县代理
			regionClick() {
				this.checkRegion = !this.checkRegion
				this.$store.dispatch('cutMap', {
					mapType: 0
				})
			},
			// 点击智慧习总数
			classroomCkick() {
				this.$store.dispatch('cutMap', {
					mapType: 1
				})
				this.checkClassroom = !this.checkClassroom
			},
			getTime() {
				const date = new Date();
				const year = date.getFullYear();
				const month = date.getMonth() + 1;
				const day = date.getDate();
				this.currentDate = `${year}年${month}月${day}日`; // 格式化当前日期
			}
		}
	}
</script>


<style type="text/css" scoped>
	.left-body {
		height: 77.1vh;
		display: flex;
	}

	.left-body-arc {
		display: flex;
		align-items: center;
		padding-top: 18vh;
		margin-left: -3vw;
	}

	.left-body-number {
		height: 100%;
		display: flex;
		padding: 2vh 0 2vh 1vw;
		flex-direction: column;
		align-self: flex-start;
		justify-content: space-between;
	}

	.left-body-time {
		width: 22vw;
		background-image: url('../../assets/home/left_top.svg');
		background-size: 100% 100%;
		text-align: left;
		color: #6cf;
		padding: 0.4vh 0.7vw;
		font-size: 0.7vw;
	}

	.left-body-number-1 {
		width: 24vw;
		height: 12vh;
		background: url('../../assets/home/left_1.png');
		background-size: 100% 100%;
		color: #6cf;
	}

	.left-body-number-1-check {
		width: 24vw;
		height: 12vh;
		background: url('../../assets/home/left_1_check.png');
		background-size: 100% 100%;
		color: #6cf;
	}



	.left-body-number-1:hover {
		cursor: pointer;
		transform: scale(1.03);
		/* 图片鼠标悬浮时放大 20% */
	}

	.left-body-number-2:hover {
		cursor: pointer;
		transform: scale(1.03);
		/* 图片鼠标悬浮时放大 20% */
	}

	.left-body-number-3:hover {

		transform: scale(1.01);
		/* 图片鼠标悬浮时放大 20% */
	}

	.left-body-number-4:hover {

		transform: scale(1.01);
		/* 图片鼠标悬浮时放大 20% */
	}

	.left-body-number-2 {
		width: 21.2vw;
		height: 12vh;
		background-image: url('../../assets/home/left_2.png');
		background-size: 100% 100%;
		color: #6cf;
	}

	.left-body-number-2-check {
		width: 21.2vw;
		height: 12vh;
		background: url('../../assets/home/left_2_check.png');
		background-size: 100% 100%;
		color: #6cf;
	}

	.left-body-number-3 {
		width: 20vw;
		height: 12vh;
		background-image: url('../../assets/home/left_3.png');
		background-size: 100% 100%;
		color: #6cf;
	}

	.left-body-number-4 {
		width: 22vw;
		height: 12vh;
		background-image: url('../../assets/home/left_4.png');
		background-size: 100% 100%;
		color: #6cf;
	}

	.left-body-number-name {
		margin-left: 1vw;
		font-size: 0.8vw
	}

	.left-body-number-value {
		font-size: 1.8vw;
		margin-left: 7vw;
	}

	/* <p class="left-body-number-name">区县代理总数</p><b canplay="left-body-number-value">365</b> */
</style>