<template>
	<div id="map-container"
		style="width: 100%; height: 100%;z-index: 100000;display: flex;align-items: center;justify-content: center;">
	</div>
	<img @click="returnClick" v-show="returnIcon" class="returnicon" src="../../assets/home/return.png" alt="" srcset=""
		title="返回上一级" />
</template>

<script>
	// 提前引入echarts地图json文件
	import map_100000 from '../../utils/mapData/100000.json';
	import map_110000 from '../../utils/mapData/110000.json';
	import map_120000 from '../../utils/mapData/120000.json';
	import map_130000 from '../../utils/mapData/130000.json';
	import map_140000 from '../../utils/mapData/140000.json';
	import map_150000 from '../../utils/mapData/150000.json';
	import map_210000 from '../../utils/mapData/210000.json';
	import map_220000 from '../../utils/mapData/220000.json';
	import map_230000 from '../../utils/mapData/230000.json';
	import map_310000 from '../../utils/mapData/310000.json';
	import map_320000 from '../../utils/mapData/320000.json';
	import map_330000 from '../../utils/mapData/330000.json';
	import map_340000 from '../../utils/mapData/340000.json';
	import map_350000 from '../../utils/mapData/350000.json';
	import map_360000 from '../../utils/mapData/360000.json';
	import map_370000 from '../../utils/mapData/370000.json';
	import map_410000 from '../../utils/mapData/410000.json';
	import map_420000 from '../../utils/mapData/420000.json';
	import map_430000 from '../../utils/mapData/430000.json';
	import map_440000 from '../../utils/mapData/440000.json';
	import map_450000 from '../../utils/mapData/450000.json';
	import map_460000 from '../../utils/mapData/460000.json';
	import map_500000 from '../../utils/mapData/500000.json';
	import map_510000 from '../../utils/mapData/510000.json';
	import map_520000 from '../../utils/mapData/520000.json';
	import map_530000 from '../../utils/mapData/530000.json';
	import map_540000 from '../../utils/mapData/540000.json';
	import map_610000 from '../../utils/mapData/610000.json';
	import map_620000 from '../../utils/mapData/620000.json';
	import map_630000 from '../../utils/mapData/630000.json';
	import map_640000 from '../../utils/mapData/640000.json';
	import map_650000 from '../../utils/mapData/650000.json';
	import map_710000 from '../../utils/mapData/710000.json';
	import map_810000 from '../../utils/mapData/810000.json';
	import map_820000 from '../../utils/mapData/820000.json';
	export default {
		props: {

		},
		data() {
			return {
				returnIcon: false,
				mapChart: undefined,
			}
		},
		mounted() {
			// this.renderMap(this.mapData, this.mapCode)
		},
		watch: {
			// 地图数据更新后再渲染地图，要不然地图数据和vuex数据不同步
			mapData(newValue) {
				this.renderMap(this.mapData, this.mapCode,this.getmapType,this.getmax)
			},
		},
		computed: {
			// 当前需要显示地图的编码，就是 dataMap has列表中的编码
			mapCode() {
				return this.$store.state.mapCode
			},
			// 地图中的数据
			mapData() {
				return this.$store.state.mapData
			},
			//  has列表数据
			dataMap() {
				return this.$store.state.dataMap
			},
			mapObjectList() {
				return this.$store.state.mapObjectList
			},
			getTop() {
				return this.$store.state.mapObjectList.find(e => {
					return e.id == this.$store.state.mapCode
				}).top
			},
			getLeft() {
				return this.$store.state.mapObjectList.find(e => {
					return e.id == this.$store.state.mapCode
				}).left
			},
			getmapType(){
				return this.$store.state.mapType
			},
			getmax(){
				return this.$store.state.max
			}
		},
		methods: {
			// 返回国家地图
			returnClick() {
				this.$store.commit('returnChinaMap', '')
			},
			// 渲染地图
			// mapData : 地图数据 areaNumber:地区编号
			renderMap(mapData, areaNumber,mapType,max) {
				// document.getElementById('map-container').innerHTML = '';
				// this.mapChart = undefined
				// 当地图是省级就显示返回按钮
				this.returnIcon = areaNumber != 100000 ? true : false
				let china = undefined
				// 根据地区编号比对取哪个地区的echarts json文件
				switch (areaNumber) {
					case 100000:
						china = map_100000;
						break;
					case 110000:
						china = map_110000;
						break;
					case 120000:
						china = map_120000;
						break;
					case 130000:
						china = map_130000;
						break;
					case 140000:
						china = map_140000;
						break;
					case 150000:
						china = map_150000;
						break;
					case 210000:
						china = map_210000;
						break;
					case 220000:
						china = map_220000;
						break;
					case 230000:
						china = map_230000;
						break;
					case 310000:
						china = map_310000;
						break;
					case 320000:
						china = map_320000;
						break;
					case 330000:
						china = map_330000;
						break;
					case 340000:
						china = map_340000;
						break;
					case 350000:
						china = map_350000;
						break;
					case 360000:
						china = map_360000;
						break;
					case 370000:
						china = map_370000;
						break;
					case 410000:
						china = map_410000;
						break;
					case 420000:
						china = map_420000;
						break;
					case 430000:
						china = map_430000;
						break;
					case 440000:
						china = map_440000;
						break;
					case 450000:
						china = map_450000;
						break;
					case 460000:
						china = map_460000;
						break;
					case 500000:
						china = map_500000;
						break;
					case 510000:
						china = map_510000;
						break;
					case 520000:
						china = map_520000;
						break;
					case 530000:
						china = map_530000;
						break;
					case 540000:
						china = map_540000;
						break;
					case 610000:
						china = map_610000;
						break;
					case 620000:
						china = map_620000;
						break;
					case 630000:
						china = map_630000;
						break;
					case 640000:
						china = map_640000;
						break;
					case 650000:
						china = map_650000;
						break;
					case 710000:
						china = map_710000;
						break;
					case 810000:
						china = map_810000;
						break;
					case 820000:
						china = map_820000;
						break;
					default:
						break;
				}
				this.$echarts.registerMap("map" + areaNumber, china);
				let container = document.getElementById("map-container")
				let mapChart = this.$echarts.init(container, null, {
					renderer: "svg"
				});
				let options = {
					tooltip: {
						formatter: function(params,ticket,callback) {
							if(mapType == 0){
								const custom = params.data.percent
								return params.name + ":" + custom + "个";
							}else{
								return  params.name + ":" + params.value + "个";
							}
						}
					},
					visualMap:{
					//视觉映射组件
					top: "bottom",
					left: "left",
					show: false,
					min: 0,
					max: max,
					realtime: true, //拖拽时，是否实时更新
					calculable: true, //是否显示拖拽用的手柄
					inRange: {
						color: ['rgb(4, 44, 2)','rgb(61, 142, 49)','rgb(50, 159, 54)','rgb(31, 181, 55)','rgb(20, 188, 2)'],
						// color:['rgb(224, 252, 203)','rgb(161, 247, 100)','rgb(102, 242, 0)']
						},
					},
					series: [{
						type: "map",
						data: mapData,
						map: 'map' + areaNumber,
						top: this.getTop, //调整地图在页面的位置
						left: this.getLeft,
						zoom: 1.10, //缩放比例
						aspectScale: 0.75,
						geoIndex: 0,
						label: {
							show: true,
							color: "white"
						},
						emphasis: {
							label: {
								show: false,
								color: "white"
							},
							itemStyle: {
								areaColor: "rgb(77, 187, 199)",
							},
						},
						itemStyle: {
							borderColor: "rgb(255, 255, 255)",
							areaColor: "rgb(5, 38, 80)",

						},
					}, true]
				}
				if(areaNumber == 460000){
					options.series[0].center = [109.844902,19.0392]
					options.series[0].layoutCenter = ['50%','50%']
					options.series[0].layoutSize = '600%'
				}else{
					options.series[0].center = undefined
					options.series[0].layoutCenter = undefined
					options.series[0].layoutSize = undefined
				}
				// 防止多次触发点击事件
				mapChart.off('click')
				mapChart.on("click", (params) => {
				// 如果在dataMap has列表中则待办点击的是省，不在里面待办是点击市区
				// this.dataMap.has(params.name)
					let index = this.mapObjectList.findIndex(e => {
						return e.name == params.name
					})
					if (index != -1) {
						this.$store.dispatch('getCitySummary', params.name)
					} else {
						this.$store.dispatch('getCountyData', params.name)
						this.$store.dispatch('setmapPorvinceType',0)
					}
				});
				mapChart.setOption(options)
			}
		}
	}
</script>
<style scoped type="text/css">
	.returnicon {
		height: 5vh;
		width: 2.8vw;
		position: relative;
		bottom: 15vh;
		z-index: 100000000;
	}

	.returnicon:hover {
		cursor: pointer;
		transform: scale(1.2);
	}
</style>