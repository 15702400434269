<template>
	<div class="head-body">
		<div class="head-body-middle">
			<img src="../../assets/home/youxiang_logo.png" alt="" />
			<span>幼象科技数据平台</span>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'HomeHead',
		props: {

		}
	}
</script>

<style type="text/css" scoped>
	.head-body {
		height: 100%;
		width: 100%;
		background-image: url('../../assets/home/top.png');
		background-size: 100% 100%;
		display: flex;
		justify-content: center;
	}

	.head-body-middle {
		display: flex;
		margin-top: -5vh;
		align-items: center;
	}

	.head-body-middle img {
		height: 5.5vh;
	}

	.head-body-middle span {
		color: #1d94f0;
		font-size: 2vw;
		margin-left: 2vw;
	}
</style>